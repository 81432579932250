import React from 'react';
import posed from 'react-pose';
import ColorThief from 'colorthief'

import videojs from 'video.js'

const Box = posed.div({
  visible: {
    opacity: 1,
    transition: {
      opacity: { ease: 'easeOut', duration: 300 },
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      opacity: { ease: 'easeOut', duration: 300 },
    }
  }
});

class VideoPlayer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {              //videojs Options
        width: 960,
        height: 540,
      },
      sources: {},           //Datasources
      disableButtons: false, // Buttons erst gar nicht anzeiggen, sinnvoll fuer Teaserdarstellung
      disableColor: true,   // "Ambient" Color abschalten
      isVisible: true,
      trailerButton: false,
      fullButton: false,
      posterVisible: true,
      closeButtonVisible: false,
      color: null,
    }
    this.imgRef = React.createRef();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  async componentDidMount() {
    const trailer = (this.props.sources && this.props.sources.trailer) ? this.props.sources.trailer : {}
    const full = (this.props.sources && this.props.sources.full) ? this.props.sources.full : {}
    const disableButtons = (this.props.options && this.props.options.disableButtons) ? this.props.options.disableButtons : false
    const disableColor = (this.props.options && this.props.options.disableColor) ? this.props.options.disableColor : false

    const trailerButton = (trailer.src)
    const fullButton    = (full.src)

    const playerOptions = {
      sources: trailer,
      preload: "auto",
      children: {
        loadingSpinner: false
      },
      ...this.props.playerOptions,
    }

    this.player = videojs(this.videoNode, playerOptions, function onPlayerReady() {

    });


    this.setState({
      options: { ...this.props.playerOptions },
      sources: { ...this.props.sources },
      disableButtons: disableButtons,
      disableColor: disableColor,
      trailerButton,
      fullButton,
    }, () => {
      this.initPlayer()
    })

  }

  initPlayer = () => {
    this.player.on("pause", () => {
    })

    this.player.on("play", () => {
    })
  }

  initColor = () => {
    if (!this.state.disableColor) {
      const colorThief = new ColorThief();
      const img = this.imgRef.current;
      const result = colorThief.getColor(img, 25);
      this.setState({ color: result })
    }
  }

  hideButtons = () => {
    this.setState({ isVisible: false, closeButtonVisible: true})
  }

  showButtons = () => {
    this.setState({ isVisible: true, closeButtonVisible: false})
  }

  closePlayer = () => {
    this.player.pause()
    this.setState({ isVisible: true, posterVisible: true, closeButtonVisible: false })
  }

  playerWidth = () => {
    return (this.state.options.width) ? this.state.options.width: 960
  }

  playerHeight = () => {
    return (this.state.options.height) ? this.state.options.height : 540
  }

  showControls = () => {
    return (this.state.options.controls) ? 'controls' : ''
  }

  startTrailer = () => {
    this.setState({posterVisible: false})
    this.hideButtons()
    this.player.src({ ...this.state.sources.trailer })
    this.player.play()
  }

  startFull = () => {
    this.setState({ posterVisible: false})
    this.hideButtons()
    this.player.src({ ...this.state.sources.full })
    this.player.play()
  }

  render() {

    return (

      <div >

        <div data-vjs-player>
          <video
            ref={node => this.videoNode = node}
            className="video-js vjs-default-skin"
            width={this.playerWidth()}
            height={this.playerHeight()}
            controls={this.showControls()}>
          </video>

          { this.state.posterVisible &&
            <span>
              <img
                ref={this.imgRef}
                className="posterframe"
                src={this.state.options.poster} alt=""
                onLoad={() => {
                  this.initColor()
                }}
                />
              <button className="playButton" onClick={this.startTrailer}>Play</button>
            </span>
          }

          {this.state.closeButtonVisible &&
            <button className="Xbutton" onClick={this.closePlayer}>X</button>
          }
        </div>
      </div>
    );
  }
}

export default VideoPlayer;