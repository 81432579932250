
const sources = {
  video1: {
    trailer: {
      src: 'https://zdf-enterprises.de/x/stream-trailer/BankraubFuerAnfaenger_Trailer_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
    full: {
      src: 'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_1MB.mp4',
      type: 'video/mp4'
    },
  },
  video2: {
    trailer: {
      src: 'https://zdf-enterprises.de/x/stream-trailer/Crimson_Rivers_Trailer_Chart_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    }
  },
  video3: {
    trailer: {
      src: 'https://zdf-enterprises.de/x/stream-trailer/OttilieVonFabercastell_Trailer_englUT_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
    full: {
      src: 'https://zdf-enterprises.de/x/stream-full/OttilieVonFabercastell_InternationaleFassungTeil1_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
  },
  video4: {
    trailer: {
      src: 'https://zdf-enterprises.de/x/stream-trailer/LostKingsOfBioko_eng_OS_Trailer_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
    full: {
      src: 'https://zdf-enterprises.de/x/stream-full/LostKingsOfBioko_eng_Full_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
  },
  // video5: {
  //   trailer: {
  //     src: 'https://zdf-enterprises.de/x/stream-trailer/HON_Trailer_OS1_1080p/index.m3u8',
  //     type: 'application/x-mpegURL'
  //   },
  // },
  video5: {
    trailer: {
      src: 'https://zdf-enterprises.de/x/stream-full/Procedurals_1080p/index.m3u8',
      type: 'application/x-mpegURL'
    },
  }
};

export default sources;