import React from 'react';

import './App.css';


import Video5 from './Video5'


class App extends React.Component {
  render() {
    return (
      <Video5 {...this.props} />
    );
  }
}

export default App;
