import React from 'react';

import './App.css';

import VideoPlayer from './VideoPlayer'

import sources from './Sources'

class Video5 extends React.Component {
  render() {

    const playerOptions = {
      autoPlay: false,
      controls: true,
      width: 960,
      height: 540,
      poster: "posterframes/heirsofthenightos.png"
    }

    const options = {
      disableButtons: false,
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-10" >
            <VideoPlayer sources={sources.video5} playerOptions={playerOptions} options={options} />
          </div>
        </div>
      </div>
    );
  }
}

export default Video5;
